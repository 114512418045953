import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const textFn = /* @__PURE__ */ createRecipe('text', {
  "variant": "inherit",
  "weight": "default",
  "color": "inherit"
}, [])

const textVariantMap = {
  "variant": [
    "inherit",
    "heading-1",
    "heading-2",
    "heading-3",
    "heading-4",
    "subtitle-1",
    "body-1",
    "body-2",
    "body-3",
    "caption-1",
    "caption-2",
    "label-1",
    "label-2"
  ],
  "color": [
    "inherit",
    "white",
    "white.soft",
    "black",
    "black.soft",
    "neutral",
    "neutral.soft",
    "neutral.softest",
    "positive",
    "critical",
    "warning",
    "accent"
  ],
  "align": [
    "start",
    "center",
    "end"
  ],
  "weight": [
    "default",
    "normal",
    "medium",
    "semibold",
    "bold",
    "black"
  ],
  "italic": [
    "true",
    "false"
  ],
  "capitalize": [
    "true",
    "false"
  ],
  "decoration": [
    "none",
    "line-through",
    "underline"
  ],
  "tabularNums": [
    "true",
    "false"
  ]
}

const textVariantKeys = Object.keys(textVariantMap)

export const text = /* @__PURE__ */ Object.assign(memo(textFn.recipeFn), {
  __recipe__: true,
  __name__: 'text',
  __getCompoundVariantCss__: textFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: textVariantKeys,
  variantMap: textVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, textVariantKeys)
  },
  getVariantProps: textFn.getVariantProps,
})