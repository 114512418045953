import type { ElementType, ReactNode } from "react";
import { css, cx } from "~/design/generated/css";
import { type TextVariantProps, text } from "~/design/generated/recipes";

export type TextProps = React.HTMLProps<HTMLSpanElement> & {
  children: ReactNode;
  as?: ElementType;
  className?: string;
  maxLines?: number;
} & TextVariantProps;

export function Text({ children, as: Component = "span", className, maxLines, ...props }: TextProps) {
  return (
    <Component className={cx(text(props), className)}>
      {maxLines ? (
        <div
          style={{ "--max-lines": maxLines } as React.CSSProperties}
          className={css({
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "box",
            lineClamp: "var(--max-lines)",
            boxOrient: "vertical",
          })}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </Component>
  );
}
